import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import $ from "jquery"
import songkick from "../images/songkick.png"
import fetch from "isomorphic-fetch"
import { Container, Row, Col } from "react-bootstrap"

const GigsPage = () => {

    fetch("https://gigs.dirteez.fr")
        .then(response => response.json())
        .then((data) => {
            var gigsString = '';
            
            if(typeof data.resultsPage.results.event !== 'undefined' && data.resultsPage.results.event.length > 0) {
                gigsString = '<p>Below are the shows at which you can see us live soon.</p>';
                $.each( data.resultsPage.results.event, function( key, val ) {
                    let venueName = '';
                    if(val.venue.displayName) {
                        venueName = venueName + val.venue.displayName + ' - ';
                    }
                    venueName = venueName + val.venue.metroArea.displayName + ', ' + val.venue.metroArea.country.displayName;
                    let dateArray = val.start.date.split('-')
                    let dateString = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`
            
                    gigsString = gigsString + '<div class="gig">'
                    gigsString = gigsString + `<h3>${dateString} - ${venueName}</h3>`
                    gigsString = gigsString + `
                        <p>
                            <a target="_blank" href="https://maps.google.com/maps?q=${encodeURI(venueName)}">Get Directions</a>
                            -
                            <a target="_blank" href="${val.uri}">View Event</a>
                        </p>
                    `
                    if(val.start.time != null) {
                        let timeArray = val.start.time.split(':')
                        let timeString = `${timeArray[0]}:${timeArray[1]}`
                        gigsString = gigsString + `<p>Doors at ${timeString}</p>`
                    }
                    gigsString = gigsString + '</div>'
                });
            } else {
                gigsString = '<p>We have no shows coming up at the moment. We play regularly so watch this space.</p>'
            }
            $('#gigs').html(gigsString)  
        })
        .catch((error) => {
            console.error(error)
        })

    return (
        <Layout pageInfo={{ pageName: "gigs" }}>
            <Container className="mt-5">
                <SEO title="Gigs" />
                <h1>Upcoming Gigs</h1>
                <div id="gigs"></div> 
                <div className="my-5">
                    <img id="songkick-logo" className="img-fluid" src={songkick} alt="Powered by SongKick" />
                </div>           
            </Container>
        </Layout>
)}

export default GigsPage
